import { useMutation, useQuery } from '@apollo/client';
import { Formik, Form } from 'formik';
import { useSession } from 'contexts';
import { useEffect, useState } from 'react';
import Button from 'components/Button/Button';
import Card from 'components/Card/Card';
import { GetAdsDocument, GetAdsQuery, GetInspectionGroupsDocument, GetInspectionsDocument, InspectionDueType, UpdateInspectionGroupDocument } from 'graphql/generated';
import ADItem from './ADItem';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { sub } from 'date-fns';

export type AD = GetAdsQuery['adsForCraft'][number] & { isRecurring: boolean; isSelected: boolean; };

const ADSFlyout = function ( { closeFlyout } : { closeFlyout: () => void } ) {
    const { craftId } = useSession();

    const { data: { adsForCraft } = {}, loading } = useQuery(GetAdsDocument, { variables: { id:craftId } });
    const { data: { inspectionGroups } = {} } = useQuery( GetInspectionGroupsDocument, { variables: { where: { craftId: { equals: craftId } } } } );
    const [ updateInspectionGroupDocument ] = useMutation(UpdateInspectionGroupDocument, { onCompleted: closeFlyout, refetchQueries: [
        { query: GetInspectionGroupsDocument, variables: { where: { craftId: { equals: craftId } } }},
        { query: GetInspectionsDocument, variables: { craftId } }
    ] });
    const [ formatedAds, setFormatedAds ] = useState<AD[]>([]);
    useEffect(() => {
        if(adsForCraft){
            setFormatedAds(adsForCraft.map((ad) => ({ ...ad, isRecurring: false, isSelected: false })).sort((a, b) => new Date(b.effectiveDate).getTime() - new Date(a.effectiveDate).getTime()));
        }
    }, [adsForCraft]);


    const toggleKey = (index: number, key: string) => {
        const tempAds = [...formatedAds];
        if(tempAds[index]){
            tempAds[index][key] = !tempAds[index][key];
            setFormatedAds(tempAds);
        }
    }

    const [ initialValues, setInitialValues ] = useState({inspectionGroup: ''});
    useEffect(() => {
        if(inspectionGroups && inspectionGroups[0]?.id){
            setInitialValues({inspectionGroup: inspectionGroups[0].id});
        }
    }, [inspectionGroups]);
    return (
        <Formik enableReinitialize initialValues={initialValues} onSubmit={(values : typeof initialValues)=>{
            updateInspectionGroupDocument( {
                variables: {
                    input: {
                        id: values.inspectionGroup,
                        inspections: {
                            createMany: { data : formatedAds.filter((ad) => ad.isSelected).map((ad) => ({
                                name: ad.title,
                                dueMethod: ad.isRecurring ? InspectionDueType.Unknown : InspectionDueType.Date,
                                dueDate: ad.effectiveDate,
                                doesRepeat: ad.isRecurring,
                                craftId: craftId,
                                faaInfo: {
                                    adNumber: ad?.adNumber,
                                    effectiveDate: ad?.effectiveDate,
                                    description: ad?.description,
                                    title: ad?.title,
                                    subject: ad?.subject,
                                    link: ad?.link
                                }
                            }))}
                        }
                    }
                }
             } );
        }}>
        {({ isSubmitting, values, setFieldValue }) => (
            <Form className='p-2 pb-20'>
                <Card>
                    {loading ? <p>Loading...</p> 
                     :
                        <>
                            { !adsForCraft?.length ? 
                                <p>No ADs found for this aircraft</p> :
                                <div className="flex flex-col gap-1 w-full">
                                    <div className='flex justify-between text-lg font-semibold -mx-2 border-b-2 border-brand-dark'>
                                        <h1>Select</h1>
                                        <h1>Name/Description</h1>
                                        <h1>Recurring?</h1>
                                    </div>
                                    {formatedAds?.map((ad, index) => (
                                        <ADItem key={ad.id} { ...{ index, ad, toggleKey }} />
                                    ))}
                                </div>   
                            }
                        </>
                    }
                </Card>
                <div className="px-4 mt-4 flex items-center justify-between">
                    <div className='flex flex-col'>
                        <h1 className="text-lg font-semibold mb-2">Import into Inspection Group</h1>
                        <div className="flex items-stretch grow relative group">
                            <select
                                onChange={(e) => setFieldValue('inspectionGroup', e.target.value)}
                                className="absolute w-full h-full z-10 opacity-0 cursor-pointer p-0"
                                value={values.inspectionGroup}>
                                {inspectionGroups?.map((item, index) => {
                                    return (
                                        <option value={item.id} key={index}>
                                            {item.name}
                                        </option>
                                    );
                                })}
                            </select>
                            <div className={`bg-brand text-sm flex font-semibold justify-center items-center rounded relative`}>
                                <span className="text-white flex w-full justify-center py-1 px-3 items-center select-none capitalize">
                                {inspectionGroups?.find((group) => group.id === values.inspectionGroup)?.name || 'Select Inspection Group'}
                                </span>
                                <ChevronDownIcon className={`text-white border-l h-full w-6 py-[6.075px] p-1 rounded-r`} />
                            </div>
                        </div>
                    </div>
                    <div className='flex gap-3'>
                        { formatedAds.find((ads) => ads.isSelected === false) && <Button text="Select All" size="xs" type='button' onClick={()=> setFormatedAds(formatedAds.map(value => ({...value, isSelected: true}))) } /> }
                        { !formatedAds.find((ads) => ads.isSelected === false) && <Button text="Deselect All" size="xs" type='button' onClick={()=> setFormatedAds(formatedAds.map(value => ({...value, isSelected: false}))) } /> }
                        { !!adsForCraft?.length && <Button text="Import" color="navy" size="xs" type='submit' disabled={isSubmitting || !formatedAds.find((tAd) => tAd.isSelected)} /> }
                    </div>
                </div>
            </Form>
        )}
        </Formik>
    );
};

export default ADSFlyout;
