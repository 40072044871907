import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useContext, useEffect, useState } from 'react';
import Button from 'components/Button/Button';
import { SignUpContext } from '..';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { GetUserInfoDocument, IntervalSubtype, NewUserSignupDocument, OnboardOrgDocument, Role, SignupForServiceDocument } from 'graphql/generated';
import Toast, { ToastLength, useToast } from 'components/Toast/Toast';
import * as Yup from 'yup';
import { FormLabel, FullFormikInput, FullFormikSelect } from 'components/Form/StandardForm';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import SegmentControl from 'components/SegmentControl/SegmentControl';
import { useNavigate } from 'react-router-dom';
import { useSession } from 'contexts';
import { OrgTypes } from 'utils/orgTypes';

const SignUpSchema = Yup.object().shape({
    ownerName: Yup.string(),
    address: Yup.string(),
    city: Yup.string(),
    zip: Yup.number(),
});

const ConfigureAircraft = (props) => {
    const { formStep, changeStep, tailInfo, prevStep, setCraftInput, login, tailNumber } = useContext(SignUpContext);
    const { user } = useSession();
    const { setOrganizationType, setOrganizationId, setLoginSession } = useSession();
    const [onboardOrg, { client }] = useMutation(OnboardOrgDocument);
    const { toastProps, showToast } = useToast();
    const navigate = useNavigate();
    const [ info, setInfo ] = useState({ year: '', make: '', model: '', homeAirportCode: '', fuelCapacity: '', fuelMeasurement: 'GALLONS', categoryClass: 'Single Engine', flightMeasurement:"Hobbs", currentHobbs:0, currentLeftTach:0, currentRightTach:0});
    useEffect(() => {
        setInfo({
            year: tailInfo?.yearMfr?.toString() ?? '',
            make: tailInfo?.manufacturer,
            model: tailInfo?.model,
            homeAirportCode: '',
            fuelCapacity: '',
            fuelMeasurement: 'GALLONS',
            categoryClass: tailInfo?.engineCount > 1 ? 'Twin Engine' : 'Single Engine',
            flightMeasurement:"Hobbs",
            currentHobbs:0,
            currentLeftTach:0,
            currentRightTach:0,
        });
    }, [tailInfo]);    

    return (
        <div className='relative'>
            <Toast {...toastProps} />
            <Formik
                validationSchema={SignUpSchema}
                initialValues={info}
                enableReinitialize
                onSubmit={async (values: typeof info) => {
                    if(localStorage.getItem("isSettingUpOrg") === "YES"){
                        alert("Your organization is being set up. Please sign in again after a few minutes.");
                        return;
                    }
                    try {
                        const isTwinHobbs = (values.flightMeasurement !== 'HOBBS' && values?.categoryClass === 'Twin Engine');
                        const craftInput = {
                            year: Number(values.year),
                            make: values.make,
                            model: values.model,
                            fuelCapacity: Number(values.fuelCapacity),
                            engineCount: values.categoryClass === 'Single Engine' ? 1 : 2,
                            tailNumber: tailNumber,
                            homeBase: values.homeAirportCode.toUpperCase(),
                            name: values.make + ' ' + values.model,
                            intervalsCraft: [
                                ...( values.flightMeasurement !== 'Tach' ? [{
                                    intervalSubType: IntervalSubtype.Hobbs,
                                    value: Number(values.currentHobbs),
                                }] : [] ),
                                ...( values.flightMeasurement !== 'HOBBS' ? [{
                                    intervalSubType: IntervalSubtype.Tach,
                                    value: Number(values.currentLeftTach),
                                }] : []),
                                ...( isTwinHobbs ? [{
                                    intervalSubType: IntervalSubtype.Tachtwo,
                                    value: Number(values.currentRightTach),
                                }] : []),
                            ],
                        };
                        setCraftInput(craftInput);
                        
                        localStorage.setItem("isSettingUpOrg", "YES");
                        const { data: orgData } = await onboardOrg({
                            variables: {
                            craft: craftInput,
                            organization: {
                                name: tailInfo?.name,
                                userOrganizationProfiles: {
                                create: [
                                    {
                                    user: {
                                        connect: {
                                        id: user ? user.id : login.user.id,
                                        },
                                    },
                                    role: Role.Admin, // Make the user an admin for new organization 
                                    }
                                ]
                                },
                            },
                            },
                        });
                        setOrganizationType( OrgTypes.PART91 );
                        setOrganizationId(orgData?.onboardOrganization?.id);
                        setLoginSession(orgData?.onboardOrganization?.accessToken, orgData?.onboardOrganization?.refreshToken);
                        localStorage.setItem("isSettingUpOrg", "NO");
                        navigate('/crafts');
                    } catch (error) {
                        const { message } = error as ApolloError;
                        showToast({ title:message, type: ToastLength.Long });
                    
                    }}
                }>
                {({ values, isSubmitting, errors, setFieldValue }) => (
                <Form className="max-w-[25rem]">
                    <div className="flex flex-col mb-4 mt-8">
                        <span className="text-3xl md:text-2xl font-bold text-brand">Confirm Your Aircraft Details</span>
                    </div>
                    <div className="flex flex-col mb-4">
                        <span className="font-semibold text-gray-400">Check the information below from your FAA registration and update for accuracy, if needed.</span>
                    </div>
                    <div className="flex items-center justify-between p-2 w-full bg-brand-pale my-6 rounded border-brand/30 border">
                        <div className="flex items-center">
                        <InformationCircleIcon className="h-4 w-4 mr-1 text-brand-electric opacity-50" />
                        <span className="text-sm text-brand font-semibold">Data loaded from tail number</span>
                        </div>
                        <span className="text-xs font-bold bg-brand-electric/20 text-brand rounded px-2 py-1">{tailInfo?.nNumber ?? tailNumber}</span>
                    </div>
                    <div className="flex gap-3">
                        <FullFormikInput name="year" label="Year" autoComplete="off" />
                        <FullFormikInput name="make" label="Make" autoComplete="off" />
                        <FullFormikInput name="model" label="Model" autoComplete="off" />
                    </div>
                    <div className="flex items-end gap-3">
                        <FullFormikInput name="homeAirportCode" label="Home Airport Code" autoComplete="off" />
                        <FullFormikInput name="fuelCapacity" label="Fuel Capacity" autoComplete="off" />
                        <FullFormikSelect name="fuelMeasurement" label="Fuel Measurement">
                            <option value="GALLONS">Gallons</option>
                            <option value="LITERS">Liters</option>
                        </FullFormikSelect>
                    </div>
                    <div className="flex flex-col gap-2 w-full">
                        <FormLabel>Category/Class</FormLabel>
                        <SegmentControl className='w-full' items={['Single Engine', 'Twin Engine']} activeItem={values.categoryClass} onChange={(newValue) => setFieldValue('categoryClass', newValue) } />
                    </div>
                    <div className="flex flex-col gap-2 w-full my-3">
                        <FormLabel>Flight Measurement Method</FormLabel>
                        <SegmentControl className='w-full' items={['Hobbs', 'Tach', 'Both']} activeItem={values.flightMeasurement} onChange={(newValue) => setFieldValue('flightMeasurement', newValue) } />
                    </div>
                    { (values.flightMeasurement === 'Hobbs' || values.flightMeasurement === 'Both') && <div className="flex flex-col gap-3">
                        <FullFormikInput name="currentHobbs" label="Current Hobbs Engine Time" autoComplete="off" />
                    </div> }
                    { values.categoryClass === 'Single Engine' && (values.flightMeasurement === 'Tach' || values.flightMeasurement === 'Both') && <div className="flex flex-col gap-3">
                        <FullFormikInput name="currentLeftTach" label="Current Engine Tach Time" autoComplete="off" />
                    </div> }
                    { values.categoryClass === 'Twin Engine' && (values.flightMeasurement === 'Tach' || values.flightMeasurement === 'Both') && <div className="flex flex-col gap-3">
                        <FullFormikInput name="currentLeftTach" label="Current Left Engine Tach Time" autoComplete="off" />
                        <FullFormikInput name="currentRightTach" label="Current Right Engine Tach Time" autoComplete="off" />
                    </div> }
                    <div className="flex flex-col gap-5 justify-center items-center mt-5">
                        <div className='flex gap-3'>
                            <Button text="Complete Setup" color="" size="sm" disabled={isSubmitting || Object.keys(errors).length > 0} type="submit" />
                        </div>
                        <div className='flex gap-3'>
                            <Button text="Go Back" color="pale" size="sm"  disabled={isSubmitting} onClick={() => changeStep(formStep, prevStep[prevStep.length - 1], true)} />
                        </div>
                    </div>
                </Form>
                )}
            </Formik>
        </div>
    );
};

export default ConfigureAircraft;
