import { CaretRightIcon, CheckInCircleIcon, CircleSelectionIcon, SandClockIcon, TimelapseIcon, CalendarIcon } from '../../assets/icons';
import { ExclamationTriangleIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { formatApiDate } from 'utils/formatter';
import { GetSquawksQuery } from 'graphql/generated';
import { FlyoutProps } from 'components/Flyout/Flyout';
import View from 'views/Part91/Aircraft/Squawks/View';
import StatusButton from 'components/StatusButton/StatusButton';

export default function Squawk({ squawk, updateFlyout, closeFlyout, refetch, ...props }: { updateFlyout: (data:any) => void, refetch: () => void, closeFlyout: () => void, squawk: GetSquawksQuery['squawks'][number] } & { [key: string]: any }) {

  const simplified = false;
  const Icon = ({ className }) => {
    switch (squawk.status) {
      case 'OPEN':
        return (
          <div className={className + ' text-brand-electric bg-brand-pale border-brand-electric'}>
            <CircleSelectionIcon className="h-6 w-6" />
          </div>
        );
      case 'RESOLVED':
        return (
          <div className={className + ' bg-slate-100 text-slate-500 border-slate-500'}>
            <CheckInCircleIcon className="h-6 w-6" />
          </div>
        );
      case 'IN_PROGRESS':
        return (
          <div className={className + ' bg-violet-100 text-brand-purple border-brand-purple'}>
            <TimelapseIcon className="h-6 w-6" />
          </div>
        );
      case 'STALLED':
        return (
          <div className={className + ' bg-slate-100 border-brand text-brand'}>
            <SandClockIcon className="h-6 w-6" />
          </div>
        );
      case 'PLANNED':
        return (
          <div className={className + ' bg-violet-100  text-brand-purple border-brand-purple'}>
            <CalendarIcon className="h-5 w-5" />
          </div>
        );
    }
  };

  if (simplified) {
    return (
      <div className="bg-white w-full p-2 flex justify-between border text-sm mb-1 rounded">
        <span className="font-semibold w-1/6">{squawk.title}</span>
        <span className="w-1/6">
          {squawk?.userProfile.user?.firstName} {squawk?.userProfile?.user?.lastName}
        </span>
        <span className="w-1/6">{formatApiDate(squawk.openedAt)}</span>
        <span className="w-1/6 capitalize">{squawk.severity.toLowerCase()}</span>
        <div className="w-1/6">
          {squawk.systemsAffected.map((item, index, arr) => {
            return (
              <>
                <span key={index}>{item.code}</span>
                <span className={`${arr.length - 1 === index ? 'hidden' : ''}`}>,&nbsp;</span>
              </>
            );
          })}
        </div>
        <span className="w-1/6">{squawk.status}</span>
      </div>
    );
  } else {
    return (
      <>
        <div
          onClick={() => updateFlyout( { title: squawk.title, content: <View closeFlyout={(didDelete) =>{
            if(didDelete) {
              refetch();
            }
            closeFlyout();
          } } id={squawk?.id} /> } )}
          className={`${
            squawk.status === 'RESOLVED' ? 'grayscale opacity-60 hover:opacity-100' : ''
          } group cursor-pointer hover:shadow-sm transition transition-smooth`}>
          <div className={`${props.last ? '' : 'border-b'} py-3 px-2 flex flex-col w-full relative`}>
            <div className="flex flex-col lg:flex-row items-center px-2 relative">
              <div className="flex flex-col w-full lg:w-1/2 pr-4">
                <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between">
                  <div className="flex items-center">
                    <Icon className="h-10 w-10 mr-4 rounded-full border flex items-center justify-center " />
                    <div className="">
                      <div className="flex items-center">
                        <h1 className="font-bold text-lg truncate w-56 text-brand-dark -mb-1">{squawk.title}</h1>
                        <ExclamationTriangleIcon
                          className={`${
                            squawk.severity === 'GROUNDED' ? '' : 'hidden'
                          } mt-[0.3rem] h-5 w-5 text-red-500 py-[0.125rem] stroke-[0.15rem]`}
                        />
                      </div>
                      <h2 className="font-semibold text-sm text-brand-dark w-56 xl:w-[360px] 2xl:w-[490px] truncate -mb-1">{squawk.description}</h2>
                      <div className="flex items-center w-full pr-8 lg:pr-0">
                        <div className="flex items-center">
                          <PencilSquareIcon className="h-10 w-10" />
                          <span className="text-sm underline">
                            {squawk?.userProfile?.user?.firstName} {squawk?.userProfile?.user?.lastName}
                          </span>
                        </div>
                        <span className="text-xs">&nbsp;- {formatApiDate(squawk.openedAt)}</span>
                      </div>
                    </div>
                  </div>
                  {/* <div className="hidden lg:flex flex-col items-end text-sm">
                    <span className="">{formatApiDate(squawk.openedAt)}</span>
                  </div> */}
                </div>
              </div>
              <div className="flex w-full lg:w-1/2 text-sm lg:justify-center mt-2 lg:mt-0 lg:pl-4">
                <div className="flex flex-col md:flex-col-reverse rounded items-stretch grow text-sm">
                  <div className="border-slate-200 -mt-[1px] md:border-b md:border-x bg-white flex items-center justify-center py-2 md:px-4 grow font-bold rounded-b">
                    <div className='flex w-1/2 items-center gap-2'>
                      <span>Status:</span>
                      <div className="flex items-center justify-end">
                        <StatusButton text={squawk.status} context='squawk' readOnly={true} className=''  />
                      </div>
                    </div>
                    <div className='flex w-1/2 items-center gap-2'>
                      <span className="flex">Severity:</span>
                      <div className="flex items-center justify-end">
                        <StatusButton text={squawk.severity} context='severity' readOnly={true} className=''  />
                      </div>
                    </div>
                  </div>
                  <hr className="hidden md:block" />
                  <div className="bg-slate-50 border-slate-200 border-b md:border-b-0 border-x border-t flex flex-col font-semibold px-4 py-1 grow rounded md:rounded-0 rounded-t">
                    {squawk.systemsAffected.map((item, index) => {
                      return (
                        <span className="capitalize" key={index}>
                          <span className="font-bold">{item.code}</span> - {item.name.toLowerCase()}
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>
              <CaretRightIcon className="md:hidden h-4 w-4 ml-4 absolute top-4 lg:top-auto right-4" />
            </div>
          </div>
        </div>
      </>
    );
  }
}
